<template>
    <v-card>
      <v-layout>
        <v-navigation-drawer
          theme="dark"
          permanent
        >
        <v-list density="compact">
          <v-list-item
            prepend-avatar="~/assets/img/logo_hiker.png"
            :subtitle="admin?.email"
            :title="admin?.name"
          >
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list v-model:opened="open" density="compact">

          <v-list nav>
          <v-list-item prepend-icon="mdi-hiking" title="Participants" value="participants" @click="eventUsersList"></v-list-item>
        </v-list>
    </v-list>
          <template v-slot:append>
                  <div class="pa-2">
                    <v-btn block @click="handleLogout">
                      Logout
                    </v-btn>
                  </div>
                </template>
        </v-navigation-drawer>
        <v-main>
        <v-app class="pa-4">
          <slot />
        </v-app>
      </v-main>
      </v-layout>
    </v-card>
  </template>

<script setup lang="ts">
import { ref } from 'vue';

const admin = useState<any>('admin')

const handleLogout = async () => {
  try {
    await $fetch('/api/v1/admin/auth/logout', {
      method: 'DELETE'
    });
    navigateTo('/admin/login');
  } catch (error) {
    console.error('Logout failed:', error);
  }
}

const eventUsersList = async () => {
  navigateTo('/admin');
}

const open = ref(['Users']);
const admins = [
        ['Management', 'mdi-account-multiple-outline'],
        ['Settings', 'mdi-cog-outline'],
      ]
const cruds = [
        ['Create', 'mdi-plus-outline'],
        ['Read', 'mdi-file-outline'],
        ['Update', 'mdi-update'],
        ['Delete', 'mdi-delete'],
      ]
</script>